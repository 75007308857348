<template>
  <v-container
    fluid
    grid-list-xs
    class="white"
    :style="{
      minHeight: this.$vuetify.breakpoint.xs
        ? '600px'
        : this.$vuetify.breakpoint.sm
        ? '760px'
        : this.$vuetify.breakpoint.mdAndUp
        ? '800px'
        : '900px'
    }"
  >
    <v-card
      v-if="loading"
      tile
      elevation="0"
      :max-width="$vuetify.breakpoint.mobile ? '100%' : '70%'"
      class="mx-auto"
    >
      <!--start tracking order head-->
      <div>
        <h4 class="pt-10 pb-5 font-weight-bold">
          {{ $t("delivery.lang_orderSuccessful") }}
        </h4>
        <h5 class="font-weight-bold">
          <v-skeleton-loader type="text" width="25%"></v-skeleton-loader>
        </h5>
        <p class="pb-6 grey--text">
          <v-skeleton-loader type="text" width="50%"></v-skeleton-loader>
        </p>
      </div>
      <!--end tracking order head-->

      <v-divider />

      <!--start order info-->
      <div class="d-flex flex-wrap justify-space-between">
        <div>
          <span class="body-2 grey--text">{{
            $t("delivery.lang_orderDate")
          }}</span>
          <v-skeleton-loader type="text"></v-skeleton-loader>
        </div>

        <div>
          <span class="body-2 grey--text">{{
            $t("delivery.lang_orderNumber")
          }}</span>
          <v-skeleton-loader type="text"></v-skeleton-loader>
        </div>

        <div>
          <span class="body-2 grey--text">{{
            $t("delivery.lang_paymentMethod")
          }}</span>
          <v-skeleton-loader type="card" max-height="50px"></v-skeleton-loader>
        </div>

        <div>
          <span class="body-2 grey--text">{{
            $t("delivery.lang_address")
          }}</span>
          <v-skeleton-loader type="sentences"></v-skeleton-loader>
        </div>
      </div>
      <!--end order info-->

      <v-divider />

      <!--start products ordered-->
      <v-skeleton-loader
        elevation="1"
        type="list-item-avatar-three-line, divider"
      ></v-skeleton-loader>
      <v-skeleton-loader
        elevation="1"
        type="list-item-avatar-two-line, divider"
      ></v-skeleton-loader>
      <!--end products ordered-->
      <v-card-text v-if="order">
        <div class="d-flex">
          <v-spacer />
          <div>
            <div class="d-flex">
              <p>{{ $t("delivery.lang_subtotal") }}</p>
              <v-spacer></v-spacer>
              <h6 class="pl-15 ml-15">{{ 21.99 | currency }}</h6>
            </div>
            <div class="d-flex" v-if="order.isDelivery === 1">
              <p>{{ $t("delivery.lang_deliveryCosts") }}</p>
              <v-spacer></v-spacer>
              <h6>{{ order.deliveryCost | currency }}</h6>
            </div>
            <div class="d-flex">
              <p>{{ $t("delivery.lang_invoiceAmount") }}</p>
              <v-spacer></v-spacer>
              <h6>{{ 21.99 | currency }}</h6>
            </div>
            <div class="d-flex">
              <p>{{ $t("delivery.lang_discount") }}</p>
              <v-spacer></v-spacer>
              <h6>{{ 21.99 | currency }}</h6>
            </div>
            <v-divider />
            <div class="d-flex">
              <h6 class="black--text">{{ $t("delivery.lang_total") }}</h6>
              <v-spacer></v-spacer>
              <h6 class="black--text">{{ 21.99 | currency }}</h6>
            </div>
            <v-divider />
          </div>
        </div>

        <div>
          <p>
            {{ $t("delivery.lang_orderSendEmail") }}
          </p>
          <p class="black--text">{{ $t("delivery.lang_thanksForOrdering") }}</p>
          <p>{{ $t("delivery.lang_localBeeTeam") }}</p>
        </div>
      </v-card-text>
    </v-card>
    <v-card
      v-else-if="!loading && order"
      tile
      elevation="0"
      :max-width="$vuetify.breakpoint.mobile ? '100%' : '70%'"
      class="mx-auto"
    >
      <!--start tracking order head-->
      <div>
        <h4 class="pt-10 pb-5 font-weight-bold">
          {{ $t("delivery.lang_orderSuccessful") }}
        </h4>
        <h5 class="font-weight-bold">
          {{ $t("delivery.lang_hello") }}
          {{ order.customerFirstName + " " + order.customerLastName }}
        </h5>
        <p class="pb-6 grey--text">
          {{ $t("delivery.lang_orderProccessing") }}
        </p>
      </div>
      <!--end tracking order head-->

      <v-divider />

      <!--start order info-->
      <div class="d-flex flex-wrap justify-space-between" v-if="order">
        <div>
          <span class="body-2 grey--text">{{
            $t("delivery.lang_orderDate")
          }}</span>
          <p class="black--text caption">
            {{ checkBreakfastMode ? "" : getDate(order.timestamp) }}
          </p>
        </div>

        <div>
          <span class="body-2 grey--text">{{
            $t("delivery.lang_orderNumber")
          }}</span>
          <p class="black--text caption">{{ order.localBeeId }}</p>
        </div>

        <div>
          <span class="body-2 grey--text">{{
            $t("delivery.lang_paymentMethod")
          }}</span>
          <v-row v-if="orderPaymentMethod">
            <v-col cols="12">
              <template>
                <div
                  v-if="orderPaymentMethod.iconType === 'fa'"
                  class="rounded-xl"
                >
                  <v-card
                    class="d-flex align-center rounded-xl"
                    elevation="0"
                    height="80"
                  >
                    <div
                      style="width: 100%; height: auto"
                      class="my-auto mx-auto justify-center align-center text-center"
                    >
                      <font-awesome-icon
                        style="
                            font-size: 30px;
                            display: block;
                            margin: 5px 0;
                            opacity: 0.6;
                          "
                        class="text--white mx-auto
                          "
                        :icon="['fal', orderPaymentMethod.icon]"
                      />
                      <span
                        class="text-uppercase font-weight-bolder text-center"
                        style="color: #515c6f !important; font-size: 120%"
                      >
                        {{ orderPaymentMethod.name }}</span
                      >
                    </div>
                  </v-card>
                </div>
                <v-card
                  v-else
                  class="d-flex align-center rounded-xl"
                  width="100%"
                  color="grey lighten-1
                    "
                  style="border: 2px solid grey"
                  height="80"
                  :key="orderPaymentMethod.id + '2'"
                >
                  <v-img
                    v-if="orderPaymentMethod.iconType === 'image'"
                    :src="orderPaymentMethod.icon"
                    class="mx-auto py-6 payment-icon"
                  />
                </v-card>
              </template>
            </v-col>
          </v-row>
        </div>

        <div v-if="order.isDelivery && order.customerAddress">
          <span class="body-2 grey--text">{{
            $t("delivery.lang_address")
          }}</span>
          <p class="black--text caption">
            {{
              order.customerAddress.streetNumber +
                " " +
                order.customerAddress.zip +
                " " +
                order.customerAddress.city
            }}
          </p>
        </div>
      </div>
      <!--end order info-->

      <v-divider />

      <!--start products ordered-->
      <v-list two-line v-if="order">
        <template v-for="item in order.items">
          <template v-if="item && isDeliveryCost(item)">
            <v-list-item :key="item.id">
              <v-list-item-avatar size="80">
                <v-img :src="getItemImage(item)" alt="" />
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title class="font-weight-bold">
                  {{ item.name }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ getItemExtras(item) }}
                  {{ item.freeText ? "(" + item.freeText + ")" : "" }}
                </v-list-item-subtitle>
                <v-list-item-subtitle
                  >{{ $t("delivery.lang_amount") }}: {{ item.amount }}
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-action-text>
                <span class="font-weight-black black--text">
                  {{ itemTotalPrice(item) | currency }}
                </span>
              </v-list-item-action-text>
            </v-list-item>

            <v-divider></v-divider>
          </template>
        </template>
        <v-divider />
      </v-list>
      <!--end products ordered-->
      <v-card-text v-if="order">
        <div class="d-flex">
          <v-spacer />
          <div>
            <div class="d-flex">
              <p>{{ $t("delivery.lang_subtotal") }}</p>
              <v-spacer></v-spacer>
              <h6 class="pl-15 ml-15">
                {{
                  (totalSum - (order.isDelivery === 1 ? deliveryCost : 0))
                    | currency
                }}
              </h6>
            </div>
            <div class="d-flex" v-if="order.isDelivery === 1">
              <p>{{ $t("delivery.lang_deliveryCosts") }}</p>
              <v-spacer></v-spacer>
              <h6>{{ deliveryCost | currency }}</h6>
            </div>
            <div class="d-flex">
              <p>{{ $t("delivery.lang_invoiceAmount") }}</p>
              <v-spacer></v-spacer>
              <h6>{{ totalSum | currency }}</h6>
            </div>
            <div class="d-flex">
              <p>{{ $t("delivery.lang_discount") }}</p>
              <v-spacer></v-spacer>
              <h6>{{ discountSum | currency }}</h6>
            </div>
            <v-divider />
            <div class="d-flex">
              <h6 class="black--text">{{ $t("delivery.lang_total") }}</h6>
              <v-spacer></v-spacer>
              <h6 class="black--text">{{ totalSum | currency }}</h6>
            </div>
            <v-divider />
          </div>
        </div>

        <v-row>
          <v-col cols="12" sm="6">
            <p class="black--text">{{ $t("delivery.lang_orderSendEmail") }}</p>
            <p>{{ $t("delivery.lang_localBeeTeam") }}</p>
          </v-col>
          <v-col cols="12" sm="6" align="right">
            <v-btn color="accent" @click="$router.push('/')">
              <v-icon> mdi-chevron-left </v-icon>
              {{ $t("generic.lang_backToMenu") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card
      v-else
      elevation="0"
      class="text-center mx-auto my-auto"
      width="60%"
    >
      <v-sheet elevation="5" class="pt-8 ml-2 mb-1">
        <div class="caption font-weight-bold">
          {{ $t("delivery.lang_orderNotFound") }}
        </div>

        <v-img src="@/assets/img/3747371.jpg" />
      </v-sheet>
    </v-card>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import ENDPOINTS from "@/plugins/axios/endpoints";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCreditCard,
  faCreditCardBlank,
  faEuroSign,
  faHandsHeart,
  faLineColumns,
  faPiggyBank,
  faReceipt
} from "@fortawesome/pro-light-svg-icons";
import { isBreakfast } from "../../helpers/breakfast";
import { DEFAULT_PAYMENT_METHOD } from "@/utils/constants";
library.add(
  faCreditCard,
  faCreditCardBlank,
  faHandsHeart,
  faEuroSign,
  faPiggyBank,
  faReceipt,
  faLineColumns
);

export default {
  name: "TrackingOrderComponent",
  components: { "font-awesome-icon": FontAwesomeIcon },
  data() {
    return {
      loading: false,
      order: null,
      paymentIcons: {
        localbee_paypal_active: {
          icon: require("@/assets/logos/paypal.png"),
          iconType: "image"
        },
        localbee_secupay_active: {
          icon: require("@/assets/logos/sp-logo.png"),
          iconType: "image"
        },
        localbee_pay_at_checkout_active: {
          icon: "money-check-edit",
          iconType: "fa"
        },
        localbee_stripe_active: {
          icon: require("@/assets/logos/stripe-logo.svg"),
          iconType: "image"
        }
      }
    };
  },
  computed: {
    ...createNamespacedHelpers("ItemGroups").mapGetters(["itemGroups"]),
    ...createNamespacedHelpers("Config").mapGetters([
      "images",
      "shop",
      "times",
      "preparationTime",
      "maintenanceMode",
      "offersDelivery",
      "offersPickUp",
      "alternativeText",
      "isDelivery",
      "shop",
      "deliveryInfo",
      "deliveryAreas",
      "deliveryPayments",
      "takeawayPayments",
      "isStillOpen"
    ]),
    /**
     * check whether an item is delivery cost or not
     * @return {function(*): boolean}
     */
    isDeliveryCost() {
      return item => !item?.randomPositionID.includes("$deliveryCost_");
    },
    checkBreakfastMode() {
      return isBreakfast();
    },
    orderPaymentMethod() {
      const methodId = this.order?.paymentId;
      if (methodId) {
        const index = this.payments.findIndex(p => p.id === methodId);
        if (index > -1) {
          return this.payments[index];
        }
      }
      return undefined;
    },
    payments() {
      let payments = this.mapPayments(
        this.isDelivery ? this.deliveryPayments : this.takeawayPayments
      );
      if (this.checkBreakfastMode) {
        //show only paypal and concardis for breakfasts
        if (payments && payments.length > 0) {
          return payments.filter(p =>
            ["localbee_takeaway_paypal_active"].includes(p.id)
          );
        }

        return [];
      } else {
        if (payments && payments.length > 0) {
          return payments.filter(p => p.active);
        }

        return [DEFAULT_PAYMENT_METHOD];
      }
    },
    totalSum() {
      let total = 0;

      this.order.items.forEach(item => {
        let itemPrice = 0;

        if (item) {
          itemPrice = item.sellPrice * item.amount;

          itemPrice -= itemPrice * (item.discount / 100);
        }

        total += itemPrice;
      });

      if(total < 0) {
        total = 0;
      }

      return total;
    },
    discountSum() {
      let total = 0;
      this.order.items.forEach(item => {
        let itemPrice = 0;
        if (item) {
          itemPrice = item.sellPrice * item.amount;

          total += itemPrice * (item.discount / 100);
        }
      });
      return total;
    },
    deliveryCost() {
      if (this.order && this.order.isDelivery === 1) {
        let deliveryCost = this.order.items.find(item =>
          item.randomPositionID.includes("$deliveryCost_")
        );
        if (deliveryCost) return deliveryCost.sellPrice;
      }
      return 0;
    }
  },
  mounted() {
    this.getOrder();
    this.$store.dispatch("Order/emptyCart");
    this.$store.commit("Order/setVoucher", null);
    this.$store.commit("Order/setOrderAsAlreadyDuplicated", false);
  },
  beforeMount() {
    if (!this.$route.query.hasOwnProperty("orderNumber")) {
      //this.$router.push('/404')
    }
  },
  methods: {
    mapPayments(payments) {
      const paymentMethods = [];

      for (const payment of payments) {
        let paymentName = payment.paymentName;
        if (
          payment.translations &&
          payment.translations[this.$i18n.locale]?.length > 0
        ) {
          paymentName = payment.translations[this.$i18n.locale];
        }
        // translate payment name
        paymentMethods.push({
          id: payment.id,
          name: paymentName,
          active: payment.active,
          icon: this.paymentIcons[payment.id]?.icon,
          iconType: this.paymentIcons[payment.id]?.iconType
        });
      }

      return paymentMethods;
    },
    getOrder() {
      this.loading = true;

      //prepare data
      let form = {};
      let url = ENDPOINTS.DELIVERY.ORDERS.GET_ORDER;
      if (this.checkBreakfastMode) {
        form.ordersUUID = this.$route.query.orderNumber.split("|");
        url = ENDPOINTS.DELIVERY.ORDERS.MERGED;
      } else {
        form.orderUUID = this.$route.query.orderNumber;
      }

      this.axios
        .post(url, form)
        .then(res => {
          if (res.status === 200) {
            this.order = res.data;
          }
        })
        .catch(err => {
          if (err.status === 500) {
            this.$swal({
              title: this.$t("delivery.lang_error"),
              icon: "error",
              text: this.$t(
                "delivery.lang_somethingWentWrongPleaseTryAgainLater"
              )
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getDate(timestamp) {
      return moment.unix(timestamp).format("DD MMM YYYY");
    },
    getItemExtras(item) {
      if (item.selectedExtras) {
        return item.selectedExtras.map(extras => " " + extras.name).toString();
      }
      return "";
    },
    //total price of item
    itemTotalPrice(item) {
      let total = 0;

      total = parseFloat(item.sellPrice) * item.amount;
      total -= total * (item.discount / 100);

      return total;
    },
    getItemImage(item) {
      let itemGroup = this.itemGroups.find(
        itemGroup => item?.itemgroupID === itemGroup.id
      );
      if (itemGroup) {
        let searchedItem = itemGroup.items.find(
          searchItem => searchItem.id === item.id
        );
        if (searchedItem) {
          return searchedItem.image;
        }
      }
      return "@/assets/img/item-1.png";
    }
  }
};
</script>

<style scoped>
.payment-icon {
  max-height: 50px !important;
  min-height: 50px !important;
  max-width: 70px;
}
</style>
