<template>
  <div>
    <!--start tracking-order-component-->
    <tracking-order-component />
    <!--end tracking-order-component-->
  </div>
</template>

<script>
//import components
import TrackingOrderComponent from "@/components/TrackingOrder/TrackingOrderComponent.vue";

export default {
  name: "TrackingOrder",

  components: { TrackingOrderComponent },
};
</script>
